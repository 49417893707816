import request, { downloadFile,applicationJson } from "@/utils/axios";

// export function getCategoryRanklist(params) {
//   return request.get("/sov/category/rankinglist", params);
// }

export function getCategoryMonth(params) {
  return request.get("/sov/category/month", params);
}

export function getCategoryOverview(params) {
  return request.get("/sov/category/overview", params);
}

export function getCategoryDistribution(params) {
  return request.get("/sov/category/distribution", params);
}

export function getCategoryTrend(params) {
  return request.get("/sov/category/trend", params);
}

export function getCategoryComparisonTrend(params) {
  return request.get("/sov/category/comparison/trend", params);
}

export function downloadGeneralView(params) {
  return downloadFile("/sov/category/report", params, "品类声量报告.xlsx");
}
export function listPageBaseInfo(){
  return request.get("/sov/category/listPageBaseInfo");
}
export function getCategoryRanklist(params) {
  return applicationJson.post("/sov/category/monthAggList", params);
}
