<template>
  <div class="switch-container">
    <input type="checkbox" id="switch" v-model="isChecked" class="switch-checkbox" @click="inputChange">
    <label for="switch" class="switch-label">
      <p class="switch-inner">
        <span>全部</span>
        <span>新上</span>
      </p>
      <span class="switch-handle">{{ text }}</span>
    </label>
  </div>
</template>
<script>
export default {
  data(){
    return{
      isChecked:false,
      text:'全部'
    }
  },
  methods: {
    inputChange(event){
      this.$emit('switch')
      if(this.isChecked){
        this.text = '全部'
      } else{
        this.text = '新上'
      }
    }
  }
}
</script>
<style scoped>
.switch-container {
  position: relative;
  display: inline-block;
}

.switch-checkbox {
  display: none;
}

.switch-label {
  display: block;
  width: 76px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
}

.switch-inner {
  width: 100%;
  height: 100%;
  background-color: #4f79bf;
  border-radius: 15px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 5px;
  color:#fff;
  font-size:12px;
}

.switch-checkbox:checked + .switch-label .switch-inner {
  background-color: #4f79bf; /* ON state color */
}

.switch-handle {
  position: absolute;
  top: 50%;
  left: 2px;
  width: 36px;
  height: 26px;
  margin-top:-13px;
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: left 0.3s;
  font-size:12px;
  line-height: 26px;
  text-align: center;
}

.switch-checkbox:checked + .switch-label .switch-handle {
  left: calc(100% - 38px);
}
</style>